import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export function Home() {
  return (
    <>
      <StaticImage
        src="../../../images/logo.png"
        placeholder="tracedSVG"
        width={300}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="The Git Stickers logo."
      />
      <h1>Git Stickers</h1>
      <p>Customize and ship git stickers right to your door!</p>
      <Link to="/create" className="btn btn-primary">Create a Sticker!</Link>
    </>
  )
}
