import * as React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/utils/SEO"
import { Home } from "../components/pages/home/Home"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home/>
  </Layout>
)

export default IndexPage
